<template>
  <el-row>
    <el-col :sm="24">
      <el-result :icon="icon" title="警告提示">
        <template slot="title">
          <div class="fts-20">您的申请已提交,请耐心等待</div>
        </template>
        <template slot="extra">
          <el-button type="primary" size="small" @click="goList"
            >返回列表</el-button
          >
          <!-- <el-button type="primary" size="small">返回列表</el-button> -->
        </template>
      </el-result>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      icon: "success",
    };
  },
  mounted() {},
  created() {},
  methods: {
    goList() {
      this.$router.push({ path: "/information/publish" });
    },
  },
  watch: {},
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
